import thunkMiddleware from "redux-thunk";
import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

import loggerMiddleware from "./middleware/logger";
import monitorReducerEnhancer from "./enhancers/monitorReducer";

import rootReducer from "./rootReducer";

const api = 1;

const middlewares = [loggerMiddleware, thunkMiddleware.withExtraArgument(api)];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer, monitorReducerEnhancer];

const composeEnhancers =
  (process.env.REACT_APP_NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    composeWithDevTools(...enhancers)) ||
  compose(...enhancers);

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, composeEnhancers);
  if (module.hot) {
    module.hot.accept("./rootReducer", () => {
      const nextRootReducer = require("./rootReducer").default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
