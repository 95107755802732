import {
  GET_APP_PROPERTIES_INIT,
  GET_APP_PROPERTIES_SUCCESS,
  GET_APP_PROPERTIES_FAILED,
  GET_APP_PROPERTIES_RESET,
} from "../../constants";

const initialState = {
  loading: false,
  code: "",
  success: false,
  data: [],
  error: "",
};

const getAppProperties = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_APP_PROPERTIES_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_APP_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
      };
    case GET_APP_PROPERTIES_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success,
        data: {},
        error: action.payload.message || action.payload.error_message || action.payload.error,
      };
    case GET_APP_PROPERTIES_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default getAppProperties;
