export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_RESET = "LOGIN_RESET";

export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_RESET = "LOGOUT_RESET";

export const VIEW_LOADING = "VIEW_LOADING";

export const TOASTER_MESSAGE_ALERT = "TOASTER_MESSAGE_ALERT";
export const REMOVE_TOASTER_MESSAGE_ALERT = "REMOVE_TOASTER_MESSAGE_ALERT";

export const LOGGED_IN_DATA = "LOGGED_IN_DATA";
export const RESET_LOGGED_IN_DATA = "RESET_LOGGED_IN_DATA";

export const VERIFY_OTP_INIT = "VERIFY_OTP_INIT";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";

export const REDIRECTED_SIGNUP_INIT = "REDIRECTED_SIGNUP_INIT";
export const REDIRECTED_SIGNUP_SUCCESS = "REDIRECTED_SIGNUP_UCCESS";
export const REDIRECTED_SIGNUP_FAILED = "REDIRECTED_SIGNUP_FAILED";
export const REDIRECTED_SIGNUP_RESET = "REDIRECTED_SIGNUP_RESET";

export const GET_POLL_LIST_INIT = "GET_POLL_LIST_INIT";
export const GET_POLL_LIST_SUCCESS = "GET_POLL_LIST_SUCCESS";
export const GET_POLL_LIST_FAILED = "GET_POLL_LIST_FAILED";
export const GET_POLL_LIST_RESET = "GET_POLL_LIST_RESET";

export const GET_POLL_RESULT_INIT = "GET_POLL_RESULT_INIT";
export const GET_POLL_RESULT_SUCCESS = "GET_POLL_RESULT_SUCCESS";
export const GET_POLL_RESULT_FAILED = "GET_POLL_RESULT_FAILED";
export const GET_POLL_RESULT_RESET = "GET_POLL_RESULT_RESET";

export const GET_LIVE_POLL_INIT = "GET_LIVE_POLL_INIT";
export const GET_LIVE_POLL_SUCCESS = "GET_LIVE_POLL_SUCCESS";
export const GET_LIVE_POLL_FAILED = "GET_LIVE_POLL_FAILED";
export const GET_LIVE_POLL_RESET = "GET_LIVE_POLL_RESET";

export const GET_ACTIVE_PLAYERS_INIT = "GET_ACTIVE_PLAYERS_INIT";
export const GET_ACTIVE_PLAYERS_SUCCESS = "GET_ACTIVE_PLAYERS_SUCCESS";
export const GET_ACTIVE_PLAYERS_FAILED = "GET_ACTIVE_PLAYERS_FAILED";
export const GET_ACTIVE_PLAYERS_RESET = "GET_ACTIVE_PLAYERS_RESET";

export const PUT_CAST_VOTE_INIT = "PUT_CAST_VOTE_INIT";
export const PUT_CAST_VOTE_SUCCESS = "PUT_CAST_VOTE_SUCCESS";
export const PUT_CAST_VOTE_FAILED = "PUT_CAST_VOTE_FAILED";
export const PUT_CAST_VOTE_RESET = "PUT_CAST_VOTE_RESET";

export const GET_APP_PROPERTIES_INIT = "GET_APP_PROPERTIES_INIT";
export const GET_APP_PROPERTIES_SUCCESS = "GET_APP_PROPERTIES_SUCCESS";
export const GET_APP_PROPERTIES_FAILED = "GET_APP_PROPERTIES_FAILED";
export const GET_APP_PROPERTIES_RESET = "GET_APP_PROPERTIES_RESET";

export const GET_LIVE_POLL_STATS_INIT = "GET_LIVE_POLL_STATS_INIT";
export const GET_LIVE_POLL_STATS_SUCCESS = "GET_LIVE_POLL_STATS_SUCCESS";
export const GET_LIVE_POLL_STATS_FAILED = "GET_LIVE_POLL_STATS_FAILED";
export const GET_LIVE_POLL_STATS_RESET = "GET_LIVE_POLL_STATS_RESET";
