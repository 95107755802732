import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import loadable from "@loadable/component";
import axios from "axios";

import pkg from "../package.json";
import configureStore from "./Store/configureStore";
import Loader from "./Common/Loader";
import ScrollToTop from "./Common/ScrollToTop";

import "./index.css";

const Layout = loadable(() => import("./Layout"), {
  fallback: <Loader isScreenBackDrop />,
});

axios.interceptors.request.use(
  async function (config) {
    config.headers.Version = pkg.version;
    config.headers.Source = 2;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const store = configureStore(window.__PRELOADED_STATE__);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Layout />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
