import {
  TOASTER_MESSAGE_ALERT,
  REMOVE_TOASTER_MESSAGE_ALERT,
} from "../../constants";

const initialState = {
  toasterMessage: "",
  status: "",
  timeOut: "",
};

const toaster = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOASTER_MESSAGE_ALERT:
      return {
        ...state,
        toasterMessage: action.payload.toasterMessage,
        status: action.payload.status,
        timeOut: action.payload.timeOut,
      };
    case REMOVE_TOASTER_MESSAGE_ALERT:
      return {
        toasterMessage: "",
        status: "",
        timeOut: "",
      };
    default:
      return state;
  }
};

export default toaster;
