import styled, { keyframes } from "styled-components";

export const ring = keyframes`
 0% { transform:  rotate(0deg); }
  100% { transform:  rotate(360deg); }
`;

export const LoaderStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &.full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: 20;
  }
  &.backdrop {
    background: rgba(18, 0, 39, 0.78);
    backdrop-filter: blur(3px);
  }

  .loader {
    width: ${(props) => (props.size ? props.size : "40px")};
    height: ${(props) => (props.size ? props.size : "40px")};
    display: inline-block;
    overflow: hidden;
    position: relative;
    border: 5px solid
      ${(props) => (props.backgroundColor ? props.backgroundColor : "rgba(255,255,255,0.7)")};
    border-top-color: transparent;
    border-radius: 50%;
    animation: ${ring} 1s linear infinite;
  }
`;
