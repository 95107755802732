import {
    REDIRECTED_SIGNUP_INIT,
    REDIRECTED_SIGNUP_SUCCESS,
    REDIRECTED_SIGNUP_FAILED,
    REDIRECTED_SIGNUP_RESET,
  } from "../../constants";
  
  const initialState = {
    loading: false,
    data: {},
    code: "",
    success: false,
    error: "",
  };
  
  const redirectedSignUp = (state = initialState, action = {}) => {
    switch (action.type) {
      case REDIRECTED_SIGNUP_INIT:
        return {
          ...state,
          loading: true,
        };
      case REDIRECTED_SIGNUP_SUCCESS:
        return {
          ...state,
          loading: false,
          code: action.payload.code,
          success: action.payload.success,
          data: action.payload.data || {},
          error: "",
        };
      case REDIRECTED_SIGNUP_FAILED:
        return {
          ...state,
          loading: false,
          code: action.payload.code || action.payload.data.code,
          success: action.payload.success,
          data: {},
          error: action.payload.data.message || action.payload.data,
        };
  
      case REDIRECTED_SIGNUP_RESET:
        return {
          ...initialState,
        };
  
      default:
        return state;
    }
  };
  
  export default redirectedSignUp;
  