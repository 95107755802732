import { LOGGED_IN_DATA, RESET_LOGGED_IN_DATA } from "../../constants";

const initialState = {
  data: null,
};

const loggedInData = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGGED_IN_DATA:
      return {
        data: action.payload,
      };
    case RESET_LOGGED_IN_DATA:
      return {
        data: null,
      };
    default:
      return state;
  }
};

export default loggedInData;
