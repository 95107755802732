import { VIEW_LOADING } from "../../constants";
const initialState = {
  status: false,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_LOADING:
      return {
        ...state,
        status: action.loading,
      };
    default:
      return state;
  }
};
export default loading;
