import { combineReducers } from "redux";

import { LOGOUT_FAILED, LOGOUT_SUCCESS } from "./constants";
import playground from "./reducers";

const appRedcucer = combineReducers({
  playground,
});

const rootReducer = (state, action) => {
  return appRedcucer(state, action);
};

const initialReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS || action.type === LOGOUT_FAILED) {
    state = undefined;
    window.location.reload();
  }
  return rootReducer(state, action);
};

export default initialReducer;
