import { combineReducers } from "redux";

import loading from "./Loading";
import login from "./Login";
import verifyOtp from "./VerifyOtp";
import logout from "./Logout";
import getAppProperties from "./AppProperties";
import toaster from "./Toaster";
import loggedInData from "./LoggedInData";
import getPollList from "./GetPollList";
import getPollResult from "./GetPollResult";
import getLivePoll from "./GetLivePoll";
import getActivePlayers from "./GetActivePlayers";
import putCastVote from "./CastVote";
import redirectedSignUp from "./RedirectedSignUp";

export default combineReducers({
  loading,
  login,
  verifyOtp,
  logout,
  getAppProperties,
  toaster,
  loggedInData,
  getPollList,
  getPollResult,
  getLivePoll,
  getActivePlayers,
  putCastVote,
  redirectedSignUp
});
